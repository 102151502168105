$(document).ready(initPage);

function initPage() {
  
  $('.select-container select')
    .focus(function() {
      $(this).parent().addClass('focused');
    })
    .blur(function() {
      $(this).parent().removeClass('focused');
    });

  function checkScroll() {
    if($(window).scrollTop() > 0){
      $('body').addClass('is-scrolled');
    } else {
      $('body').removeClass('is-scrolled')
    }
  }

  checkScroll();
  $(window).scroll(function(){
    checkScroll();
  });
  
  $('.header-toggler').click(function(){
    $('body').toggleClass('is-mobile-menu');
  });

  const introSwiper = new Swiper('.swiper--intro', {
    loop: true,
    pagination: {
      el: '.swiper--intro .swiper-pagination',
      type: 'bullets',
      clickable: true
    },
  });
  
  const experienceSwiper = new Swiper('.swiper--experience', {
    loop: true,
    navigation: {
      nextEl: '.swiper--experience .swiper-next',
      prevEl: '.swiper--experience .swiper-prev',
    },
    pagination: {
      el: '.swiper--experience .swiper-pagination',
      type: 'bullets',
      clickable: true
    },
  });

  $('.faq-h').click(function(){
    $(this).parent().toggleClass('is-active');
    $(this).parent().siblings().removeClass('is-active');
  });

  $('.link-btn').on('click', function(event) {
    event.preventDefault();

    const target = this.hash;
    const $target = $(target);

    $('html, body').stop().animate({
      'scrollTop': $target.offset().top
    }, 900, 'swing', function() {
      window.location.hash = target;
    });

    
  });

  let activeState; // Declare activeState outside of the event handler to make it accessible globally

  $('.state-rules-map .state').mouseover(function(event) {
    const gElement = $(event.currentTarget);
    const gOffset = gElement.offset();
    
    const getBBox = this.getBBox();
    const bWidth = getBBox.width;
    const bHeight = getBBox.height;
    
    const popupLeft = gOffset.left;
    const popupTop = gOffset.top;
    
    const title = $(this).data('title');
    const link = $(this).data('link');

    activeState = $('<div>').addClass('state-map-title').html(title);

    activeState.css({
      left: popupLeft,
      top: popupTop,
      width: bWidth,
      height: bHeight
    });

    $('body').append(activeState);
  }).mouseout(function(){
    if (activeState) {
        activeState.remove();
    }
  });

  $('.state-rules-map .state').click(function(event) {
    const gElement = $(event.currentTarget);
    
    const targetId = gElement.data('link');
    
    if ($('#' + targetId).length) {

      const targetOffset = $('#' + targetId).offset().top - $('.header').outerHeight() - 10;

      $('html, body').animate({
        scrollTop: targetOffset
      }, 1000);
    }
  })
  
}
